
import { Component, Prop, Emit, Inject } from "vue-property-decorator"
import EditMissionScope from "@/components/missions/MissionScopeEditor2.vue"
import {
  MissionLead,
  AddPricingProposalActivityMutation,
  UpdateMissionPricingProposalMutation,
  UpdatePricingProposalActivityMutation,
  DeletePricingProposalActivityMutation,
  UpdateMissionPricingProposalMutationMutation,
  DeletePricingProposalActivityMutationMutation,
  MissionProposal,
} from "@/gql"
import { RefetchQueryDescription } from "apollo-client/core/watchQueryOptions"
import CurrencySelect from "@/components/widgets/input/CurrencySelect.vue"
import { ContractingEntities } from "@/constants"
import MissionPricing2, { Activity } from "@/mixins/MissionPricing2"
import { mixins } from "vue-class-component"
import Banner from "@/components/banners/Banner.vue"
import PricingProposalPrintable from "@/components/missions/PricingProposalPrintable.vue"

@Component({
  components: {
    Banner,
    CurrencySelect,
    EditMissionScope,
    PricingProposalPrintable,
  },
})
export default class PricingEditor2 extends mixins(MissionPricing2) {
  page: string | null = null
  @Prop({ required: true }) readonly section?: string
  @Prop({ required: true }) readonly missionLead!: MissionLead
  @Prop({ required: false }) readonly missionProposal!: MissionProposal
  @Prop() readonly widget?: boolean

  @Inject()
  refetchQueries!: RefetchQueryDescription

  loading = false
  ContractingEntities = ContractingEntities

  showRemovePricingActivityDialog = false
  activeActivity: Activity | null = null

  get currency() {
    return this.form.currencyCode || ""
  }

  onAddPricingActivity() {
    this.pricingActivities.push({ name: "" })
    // Initialize applicable deliverables
    this.form.otherCostDistributions.push([])
  }

  async onPricingChange(variables: { [key: string]: any }) {
    const result = await this.mutate<UpdateMissionPricingProposalMutationMutation>({
      mutation: UpdateMissionPricingProposalMutation,
      variables: {
        id: this.pricingProposal.id,
        totalPrice: parseFloat(this.totalCostToClient),
        ...variables,
      },
    })

    if (result.data?.updateMissionPricingProposal.missionPricingProposal) {
      this.onSaveComplete()
    }
  }

  async removePricingActivity(index: number) {
    const removeItems = () => {
      this.pricingActivities.splice(index, 1)
      this.form.pricingActivities.splice(index, 1)
      this.form.pricingActivityQuantities.splice(index, 1)
      this.form.pricingActivityCosts.splice(index, 1)
      this.form.otherCostDistributions.splice(index, 1)

      this.activeActivity = null
      this.showRemovePricingActivityDialog = false
    }

    if (!this.pricingActivities[index].id) {
      removeItems() // Remove empty activity
      return
    }

    this.loading = true

    // mutate
    const result = await this.mutate<DeletePricingProposalActivityMutationMutation>({
      mutation: DeletePricingProposalActivityMutation,
      variables: {
        id: this.pricingActivities[index].id,
      },
      refetchQueries: this.refetchQueries,
      done: () => {
        this.loading = false
      },
    })

    if (result.data && !result.data.deleteMissionPricingProposalActivity.error) {
      removeItems()
      this.onSaveComplete()
    }
  }

  serializeDeliverables(index: number) {
    const deliverables = this.form.otherCostDistributions[index], // number[] with deliverable id as index
      applicable = deliverables.reduce((filtered, value, index) => {
        if (value) filtered[index] = value // add index to array if checked
        return filtered
      }, {} as { [index: number]: number })

    return JSON.stringify(applicable)
  }

  async onPricingActivityChange(activityIndex: number, variables: { [key: string]: any }) {
    for (const key in variables) {
      if (Object.prototype.hasOwnProperty.call(variables, key)) {
        if (!variables[key].toString().trim()) {
          this.addError("Please specify required fields")
          return
        }
      }
    }

    const activity = this.pricingActivities[activityIndex]
    if (activity.id) {
      // update
      const result = await this.mutate({
        mutation: UpdatePricingProposalActivityMutation,
        variables: {
          id: activity.id,
          ...variables,
        },
      })
      if (result.data && !result.data.updateMissionPricingProposalActivity.error)
        this.onSaveComplete()
    } else {
      // create
      const result = await this.mutate({
        mutation: AddPricingProposalActivityMutation,
        variables: {
          missionPricingProposalId: this.pricingProposal.id,
          name: this.form.pricingActivities[activityIndex],
        },
      })

      if (result.data?.addMissionPricingProposalActivity.activity) {
        this.pricingActivities[activityIndex].id =
          result.data.addMissionPricingProposalActivity.activity.id

        this.onSaveComplete()
      }
    }
  }

  @Emit("save")
  onSaveComplete() {
    return true
  }

  mounted() {
    this.page = this.section || "pricing"
  }
}
