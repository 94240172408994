var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-expansion-panels',{attrs:{"flat":""},model:{value:(_setup.open),callback:function ($$v) {_setup.open=$$v},expression:"open"}},[_c('v-expansion-panel',{staticClass:"outlined-card"},[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"default",fn:function({ open }){return [_c('div',{},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',{staticClass:"d-flex align-center"},[_c('h2',{staticClass:"font-size-18"},[_vm._v("Team roles")])]),_c(_setup.Icon,{key:open,attrs:{"name":open ? 'alt-arrow-up' : 'alt-arrow-down',"size":"24"}})],1),(_vm.mission?.missionExecutionStaffingPositions?.length)?[_c(_setup.TeamCardRole,{attrs:{"name":_vm.mission.missionExecutionStaffingPositions[0].name,"pendingApplications":_vm.mission.operational &&
                  _vm.mission.missionExecutionStaffingPositions[0].pendingMissionStaffingApplications
                    .length
                    ? _vm.mission.missionExecutionStaffingPositions[0]
                        .pendingMissionStaffingApplications.length
                    : 0,"state":_vm.mission.missionExecutionStaffingPositions[0].state,"description":_vm.mission.missionExecutionStaffingPositions[0].description},on:{"click":function($event){return _setup.onMissionClick(_vm.mission.missionExecutionStaffingPositions[0].id)}}})]:_vm._e()],2)]}}])}),_c('v-expansion-panel-content',[(_vm.mission?.missionExecutionStaffingPositions?.length)?[_c('div',_vm._l((_vm.mission.missionExecutionStaffingPositions.slice(1)),function(position){return _c(_setup.TeamCardRole,{key:position.id,attrs:{"name":position.name,"pendingApplications":_vm.mission.operational && position.pendingMissionStaffingApplications.length
                  ? position.pendingMissionStaffingApplications.length
                  : 0,"state":position.state,"description":position.description},on:{"click":function($event){return _setup.onMissionClick(position.id)}}})}),1)]:_c('FeedBackMessage',{staticClass:"mt-5",attrs:{"preset":"emptyState","resource":"team roles","customMessage":"No roles have been created for this mission. "},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('primary-button',{staticClass:"mt-6",attrs:{"loading":_setup.isSubmitting},on:{"click":_setup.onCreateTeam}},[_vm._v("Generate team roles")])]},proxy:true},{key:"icon",fn:function(){return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c(_setup.Icon,{staticClass:"mb-1",attrs:{"name":"users-group-two-rounded","size":"24"}})],1)]},proxy:true}])})],2)],1)],1),_c(_setup.ApplicantDetailModal,{attrs:{"positionId":_setup.activePositionId,"missionLifecycle":_vm.mission},on:{"close":function($event){_setup.showApplicationDialog = false}},model:{value:(_setup.showApplicationDialog),callback:function ($$v) {_setup.showApplicationDialog=$$v},expression:"showApplicationDialog"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }