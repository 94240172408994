import { ApolloError } from "apollo-client"
import { PageState } from "@/constants"
import { Ref } from "vue"

export const usePageState = (state: Ref<PageState>) => {
  const handlePageState = (error: ApolloError) => {
    if (error.graphQLErrors) {
      state.value = PageState.Error
    } else if (error.networkError) {
      state.value = PageState.NetworkError
    }
  }

  return { handlePageState }
}
