
import {
  ApproveMissionStaffingApplicationMutation,
  MissionStaffingApplication,
  MissionStaffingPosition,
  RejectMissionStaffingApplicationMutation,
  MissionStaffingPositionsQuery,
  MissionStaffingPositionsQueryQuery,
  MissionStaffingPositionDetailFragmentFragment,
  ConsultantTalent,
  TalentPoolQuery,
  ShortlistMissionStaffingApplicationMutation,
  ConsultantContract,
  MissionLifecycle,
  MissionLifecyclesQuery,
} from "@/gql"
import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import AddExecutionStaffPositionForm from "@/components/missions/AddExecutionStaffPositionForm.vue"
import UserCard from "@/views/missions/details/UserCard.vue"
import CreateConsultantContractForm from "@/components/consultant/forms/CreateConsultantContractForm.vue"
import SectionCard from "@/views/missions/details/SectionCard.vue"
import { FetchResult } from "apollo-link"
import Loader from "@/components/widgets/common/Loader.vue"
import FeedBackMessage from "@/components/content/FeedBackMessage.vue"
import ActivityTimeline from "@/components/widgets/timeline/ActivityTimeline.vue"
import ApplicationCard from "@/components/missions/ApplicationCard.vue"
import ApplicantDetail from "@/views/talent/ApplicantDetail.vue"
import DocumentCard from "@/components/content/DocumentCard.vue"
import { FilterType } from "@/components/widgets/common/FilterBar.vue"
import UserAvatar from "@/components/profile/UserAvatar.vue"
import ConsultantContractSummary from "@/components/consultant/ConsultantContractSummary.vue"

@Component({
  components: {
    UserCard,
    SectionCard,
    CreateConsultantContractForm,
    AddExecutionStaffPositionForm,
    Loader,
    FeedBackMessage,
    ActivityTimeline,
    ApplicationCard,
    ApplicantDetail,
    DocumentCard,
    ConsultantContractSummary,
    UserAvatar,
  },
})
export default class MissionTeamPositions extends Vue {
  @Prop({ required: true }) readonly missionLifecycle!: MissionLifecycle
  @Prop({ required: true }) readonly value!: boolean
  @Prop({ required: true }) readonly positionId!: string
  readonly MissionStaffingPositionsQuery = MissionStaffingPositionsQuery

  activeStaffPosition: MissionStaffingPosition | null = null
  showAddProposalStaffRoleModal = false
  staffingPositionAction: string | null = null
  showLinkDialog = false
  activeApplication: MissionStaffingApplication | null = null
  showCreateContractForm = false
  createContractSection = "create"
  staffingPositionLoading = false
  showRejectModal = false
  applicationAction: string | null = null
  rejectionReason = ""
  applicationActionLoading = false
  showDetailsModal = false
  staffingPosition: MissionStaffingPositionDetailFragmentFragment | null = null

  selectedApplicant: ConsultantTalent | null = null
  isLoadingDetails = false
  showConfirmDialog = false

  modalView = "default"

  @Watch("value")
  onValueChange() {
    if (this.value) {
      this.showDetailsModal = this.value
    }
  }

  get refetchQueries() {
    return [
      {
        query: MissionLifecyclesQuery,
        variables: {
          filter: {
            idIn: [this.$router.currentRoute.params.id],
          },
        },
      },
    ]
  }

  tab = "profile"

  filter: FilterType = {}

  toggleView(view: string) {
    this.modalView = view
  }

  @Watch("showDetailsModal")
  onShowDetailsModalChange() {
    if (this.showDetailsModal) {
      this.modalView = "default"
    }
  }

  onClose = () => {
    this.showDetailsModal = false
    this.$emit("close")
  }

  onProfileViewClick(application: MissionStaffingApplication) {
    this.activeApplication = application
    this.fetchApplicantDetail(application.consultant?.id)
  }

  onRejectApplication(application: MissionStaffingApplication) {
    this.showApplicationDialog("reject", application)
  }

  get staffingPositionURL() {
    return (
      window.location.host +
      this.$router.resolve({
        name: this.$routes.MissionRoleDetail,
        params: { id: this.activeStaffPosition?.id },
      }).href
    )
  }

  get filteredApplications() {
    const { search, state } = this.filter

    let applications = this.staffingPosition?.pendingMissionStaffingApplications || []

    if (search && search.length > 0) {
      applications = applications.filter((application) => {
        return application.consultant?.name?.toLowerCase().includes(search.toLowerCase())
      })
    }

    if (state) {
      applications = applications.filter((application) => {
        return application.state === state
      })
    }

    return applications
  }

  async fetchApplicantDetail(id: string) {
    this.isLoadingDetails = true

    const { data } = await this.$apollo.query({
      query: TalentPoolQuery,
      variables: {
        filter: {
          idIn: [id],
        },
        per: 1,
        page: 1,
      },
    })

    this.isLoadingDetails = false

    if (!data) {
      return this.addError("Error fetching applicant details")
    }

    this.selectedApplicant = data.talentPool.data[0]
  }

  onQueryResult(result: FetchResult<MissionStaffingPositionsQueryQuery>) {
    if (result.data) {
      this.staffingPosition = result.data.missionStaffingPositions.data[0]
    }
  }

  onSaveStaffPositionForm() {
    this.showAddProposalStaffRoleModal = false
    this.activeStaffPosition = null
  }

  onShowStaffingPositionDialog(action: string, position: MissionStaffingPosition) {
    switch (action) {
      case "edit":
        this.showAddProposalStaffRoleModal = true
        break

      case "publish":
      case "unpublish":
      case "fill":
        this.staffingPositionAction = action
        break

      case "copy-link":
        this.showLinkDialog = true
        break

      default:
        this.addError(`Unknown action: ${action}`)
        break
    }

    this.activeStaffPosition = position
  }

  showApplicationDialog(action: string, application: MissionStaffingApplication) {
    if (action === "reject") {
      this.showRejectModal = true
    } else {
      this.applicationAction = action
    }

    this.activeApplication = application
  }

  async onApplicationAction(action: string) {
    let mutation, variables

    switch (action) {
      case "approve":
        mutation = ApproveMissionStaffingApplicationMutation
        variables = {
          id: this.activeApplication ? this.activeApplication.id : 0,
        }
        break

      case "reject":
        mutation = RejectMissionStaffingApplicationMutation
        variables = {
          id: this.activeApplication ? this.activeApplication.id : 0,
          rejectionReason: this.rejectionReason,
        }
        break
      case "shortlist":
        mutation = ShortlistMissionStaffingApplicationMutation
        variables = {
          id: this.activeApplication ? this.activeApplication.id : 0,
        }
        break

      default:
        this.addError(`Unknown action: ${action}`)
        break
    }

    if (!mutation) return

    // Mutate
    try {
      this.applicationActionLoading = true

      const result = await this.$apollo.mutate({
        mutation: mutation,
        variables: {
          ...variables,
        },
        refetchQueries: this.refetchQueries,
      })

      if (result && result.data) {
        const error = result.data[Object.keys(result.data)[0]].error
        if (error) this.addMutationError(error)
        else {
          // No errors
          this.addSuccess(`Application ${action}ed successfully`)
          this.applicationAction = null
          this.activeApplication = null
        }
      }
    } catch (e) {
      this.addGraphQLError(e as Error)
    } finally {
      this.applicationActionLoading = false

      if (action === "reject") {
        this.showRejectModal = false
        this.rejectionReason = ""
        this.$refs.rejectFormObserver && (this.$refs.rejectFormObserver as any).reset()
      }
    }
  }

  openConsultantContract(contract?: ConsultantContract | null) {
    if (contract) {
      const routeData = this.$router.resolve({
        name: this.$routes.ConsultantContractAdmin,
        params: { id: contract.id },
      })
      window.open(routeData.href, "_blank")
    }
  }

  onApplicationContractAction(action: string, application: MissionStaffingApplication) {
    this.activeApplication = application

    switch (action) {
      case "view":
        this.openConsultantContract(application.consultantContract)
        break

      case "create":
        this.showCreateContractForm = true
        break

      default:
        this.addError(`Unknown action: ${action}`)
        break
    }
  }
}
