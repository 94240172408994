import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { JobTitle, MissionPricingProposal, MissionScopeFragmentFragment } from "@/gql"

export type Activity = {
  id?: number
  name: string
  missionScopePhaseId: number
  key: string
  saved: boolean
  capacityDistribution: number
}
export type Phase = { id?: number; name: string; activities: Activity[]; saving: boolean }
export type EditPricingForm = {
  markup: number
  pricingActivities: string[]
  pricingActivityQuantities: string[]
  pricingActivityCosts: string[]
  otherCostDistributions: number[][]
  contractingEntity: string | null
  currencyCode: string | null
}
export type CapacityDistributionData = { [phaseId: number]: { [activityId: number]: number } }
export type EditScopeForm = {
  role: { jobTitle: JobTitle | null; number: number }
  phases: (string | undefined)[] // [index: phaseId]
  activities: string[][]
  capacities: number[][] // [index: roleIndex][index: activityId]
  deliverables: boolean[]
  capacityDistribution: CapacityDistributionData
  targetMissionDuration: number | null
  capacityAllocation: number | null
}
export type MissionScopeJobTitle = {
  id: number
  abbr: string
  rate: number
  capacityFactor: number | null
}
export type UnallocatedPricingActivity =
  | {
      id?: number | undefined
      name: string
    }
  | undefined
@Component
export default class MissionPricing extends Vue {
  @Prop({ required: true }) readonly missionScope!: MissionScopeFragmentFragment
  @Prop({ required: true }) readonly pricingProposal!: MissionPricingProposal

  phases: Phase[] = [{ name: "", activities: [], saving: false }]

  form: EditPricingForm = {
    markup: 0,
    currencyCode: null,
    pricingActivities: [],
    pricingActivityQuantities: [],
    pricingActivityCosts: [],
    otherCostDistributions: [[]],
    contractingEntity: null,
  }

  editScopeForm: EditScopeForm = {
    role: {
      jobTitle: null,
      number: 1,
    },
    phases: [],
    activities: [[]],
    capacities: [[]],
    deliverables: [],
    capacityDistribution: {},
    targetMissionDuration: 0,
    capacityAllocation: 0,
  }

  pricingActivities: { id?: number; name: string }[] = [
    {
      name: "",
    },
  ]

  requiredRoles: MissionScopeJobTitle[] = []

  getMarkup(value: number): number {
    return value + Math.round(value * (this.form.markup / 100))
  }

  to100(index: number): number {
    const sum = this.form.otherCostDistributions[index].reduce((acc, val) => {
      return acc + Number(val)
    }, 0)

    return 100 - sum
  }

  /**
   * @param id Phase id
   */
  getPhaseFinal(id: number): number {
    return this.getMarkup(this.phaseTotals[id]) + this.getDeliverableAddOn(id)
  }

  /**
   * @param id Phase id
   */
  getDeliverableAddOn(id: number): number {
    return this.form.otherCostDistributions
      .map((pricingActDistArray, index) => {
        return ((pricingActDistArray[id] || 0) / 100) * this.otherCostTotals[index]
      })
      .reduce((acc, cur) => acc + cur, 0)
  }

  get activities(): Activity[] {
    // returns an array of all activities
    const activities = this.phases.reduce((acc, phase) => {
      return acc.concat(phase.activities)
    }, [] as Activity[])

    return activities
  }

  get pricingCurrency(): string | null {
    return this.pricingProposal.currencyCode || null
  }

  get durationWorkingDays(): number {
    return (this.editScopeForm.targetMissionDuration || 0) * 5
  }

  get capacityDistributionComputed(): { [phaseId: number]: { [activityId: number]: number[] } } {
    const projectDays =
        (this.durationWorkingDays * (this.editScopeForm.capacityAllocation || 0)) / 100,
      phases = Object.keys(this.editScopeForm.capacityDistribution) // capacityDistribution indexed by phase id

    const computeCapacityByDistribution = (percentage: number, factor: number) => {
      return ((percentage / 100) * projectDays * factor).toFixed(1) as any as number
    }

    return phases
      .map((phaseId) => {
        // object of capacity distribution indexed by activity ID
        const activityDist = this.editScopeForm.capacityDistribution[phaseId as any]

        const capacities = Object.keys(activityDist).reduce((activities, activityId) => {
          activities[activityId as any] =
            // for each role compute capacity by percentage distribution
            this.requiredRoles.map((r) =>
              computeCapacityByDistribution(
                this.editScopeForm.capacityDistribution[phaseId as any][activityId as any],
                r.capacityFactor || 1
              )
            )

          return activities
        }, {} as { [activityId: number]: number[] })

        return { id: phaseId, activities: capacities }
      })
      .reduce((computed, phase) => {
        computed[phase.id as any] = phase.activities
        return computed
      }, {} as { [phaseId: number]: { [activityId: number]: number[] } })
  }

  get activityTotals(): Record<number, number> {
    const activityTotals = Object.values(this.capacityDistributionComputed).reduce(
      (totals, activities) => {
        Object.keys(activities).map((activityId) => {
          const activitySum = activities[activityId as any].reduce((sum, cap, currentIndex) => {
            // capacity index correspond to role indexes, this allows to get the product which the cost of activity per role
            return sum + cap * this.requiredRoles[currentIndex].rate
          }, 0)

          totals[activityId as any] = activitySum
        })

        return totals
      },
      {} as Record<number, number>
    )
    return activityTotals
  }

  // [index: phaseId]
  get phaseTotals(): number[] {
    return this.phases.reduce((mapped, phase) => {
      // returns sum of activity costs
      mapped[phase.id || 0] = phase.activities
        ? phase.activities
            .filter((a) => a.id)
            .reduce((sum, a) => {
              return sum + this.activityTotals[a.id!]
            }, 0)
        : 0

      return mapped
    }, [] as number[])
  }

  // {[phaseId: number]: number[index: roleIndex]}
  get phaseCapacityTotalsComputed(): { [phaseId: number]: number[] } {
    const data = Object.keys(this.capacityDistributionComputed).reduce((totals, phaseId) => {
      totals[phaseId as any] = this.zip(
        // create a new array with capacity values grouped per role
        Object.values(this.capacityDistributionComputed[phaseId as any])
      ).map((roleCaps) => {
        // sum role capacities for this phase per role
        const roleSum = roleCaps.reduce((sum, value) => sum + Number(value), 0) as number
        return roleSum.toFixed(1) as any as number
      })

      return totals
    }, {} as { [phaseId: number]: number[] })

    return data
  }

  get roleCostTotals(): number[] {
    // group capacity totals
    const grouped = this.zip(Object.values(this.phaseCapacityTotalsComputed))

    return grouped.map((roleCaps, index) => {
      // sum role capacities for this phase per role
      const roleSum = roleCaps.reduce((sum, value) => sum + Number(value), 0) as number
      return Math.round(roleSum * this.requiredRoles[index].rate)
    })
  }

  get netValue(): number {
    return this.roleCostTotals.length && this.roleCostTotals.reduce((acc, cur) => acc + cur, 0)
  }

  // [index: phaseIndex]
  get otherCostTotals(): number[] {
    return this.pricingActivities.map((a, index) => {
      const q = parseFloat(this.form.pricingActivityQuantities[index]) || 0,
        c = parseFloat(this.form.pricingActivityCosts[index]) || 0
      return (q === Infinity ? 0 : q) * (c === Infinity ? 0 : c)
    })
  }

  get totalAdditionalCost(): number {
    return this.otherCostTotals.length && this.otherCostTotals.reduce((acc, cur) => acc + cur, 0)
  }

  get markups(): { [activityId: number]: number } {
    // get activity ids as keys, call getMarkup with value pair from activityTotals
    return Object.keys(this.activityTotals).reduce((markups, activityId) => {
      markups[activityId as any] = this.getMarkup(this.activityTotals[activityId as any])
      return markups
    }, {} as { [activityId: number]: number })
  }

  get totalMarkup(): number {
    return this.phaseTotals.reduce((sum, t) => sum + this.getMarkup(t), 0)
  }

  get totalCostToClient(): string {
    return (this.totalMarkup + this.totalAdditionalCost).toFixed(2)
  }

  get unallocatedPricingActivities(): UnallocatedPricingActivity[] {
    // undefined is returned to keep indexes as unallocated activities are referenced by index elsewhere
    return this.pricingActivities.map((a, index) => (this.to100(index) === 100 ? a : undefined))
  }

  @Watch("missionScope", { immediate: true })
  onMissionScopeChange(): void {
    this.initialize()
  }

  private initialize() {
    this.editScopeForm.targetMissionDuration = this.missionScope.targetMissionDuration || null
    this.editScopeForm.capacityAllocation = this.missionScope.capacityAllocation || null

    const phases = this.missionScope.phases,
      roles = this.missionScope!.staffs

    // set phases
    if (phases.length) {
      this.$set(
        this,
        "phases",
        phases.map((phase) => {
          const { id, name, activities, position } = phase

          return {
            id,
            name,
            position: position,
            activities: activities.map((activity) => ({
              id: activity.id,
              name: activity.name,
              missionScopePhaseId: activity.missionScopePhase.id,
              key: activity.id, // used to force re-render
              saved: true,
              capacityDistribution: activity.capacityDistribution,
            })),
          }
        })
      )

      // returns an array of phase names with phase id as index
      this.editScopeForm.phases = phases.reduce((mapped, p) => {
        mapped[p.id] = p.name
        return mapped
      }, [] as string[])

      // returns an array of name[] with phase id as index
      this.editScopeForm.activities = phases.reduce((mapped, phase) => {
        mapped[phase.id] = phase.activities.map((a) => a.name) // string[]
        return mapped
      }, [] as string[][])

      // returns an array of boolean with activity id as index
      this.editScopeForm.deliverables = this.missionScope.activities.reduce((mapped, a) => {
        mapped[a.id] = a.deliverable
        return mapped
      }, [] as boolean[])
    }

    if (roles.length) {
      // set roles
      this.requiredRoles = [
        ...roles.map((role) => {
          return {
            id: role.id,
            abbr: role.jobTitle.abbreviation!,
            rate: role.jobTitle.dailyRate!,
            capacityFactor: role.jobTitle.capacityFactor || 1,
          }
        }),
      ]

      const capacities = roles.map((role) => {
        // returns an array of capacities with activity id as index
        return this.missionScope.activities.reduce((mapped, a) => {
          const capacities = JSON.parse(a.capacities) // { staffId: capacity }
          mapped[a.id] = capacities ? capacities[role.id] || "" : "" // <empty string> if capacity not defined for role
          return mapped
        }, [] as number[])
      })

      this.editScopeForm.capacities = capacities
    }

    // initialize capacity distribution
    this.editScopeForm.capacityDistribution = this.missionScope.phases
      .map((p) => ({
        id: p.id,
        activities: p.activities.reduce((phaseActDist, activity) => {
          phaseActDist[activity.id] = activity.capacityDistribution || 0
          return phaseActDist
        }, {} as { [activityId: number]: number }),
      }))
      .reduce((distribution, phaseActDist) => {
        distribution[phaseActDist.id] = phaseActDist.activities
        return distribution
      }, {} as CapacityDistributionData)
  }

  @Watch("pricingProposal", { immediate: true })
  onPricingProposalChange(): void {
    if (this.pricingProposal) {
      // Set margin
      this.form.contractingEntity = this.pricingProposal.contractingEntity!
      this.form.markup = this.pricingProposal.markup || 0
      this.form.currencyCode = this.pricingCurrency!
      this.form.pricingActivities = this.pricingProposal.activities.map((a) => a.name)
      this.form.pricingActivityQuantities = this.pricingProposal.activities.map(
        (a) => a.quantity || 1 // default to 1 if quantity undefined
      ) as unknown[] as string[]
      this.form.pricingActivityCosts = this.pricingProposal.activities.map(
        (a) => a.unitCost || (0 as any) // default to zero if unit cost undefined
      )

      this.pricingActivities = [...this.pricingProposal.activities]

      this.form.otherCostDistributions = this.pricingProposal.activities.map((a) => {
        const obj: { [key: number]: number } = JSON.parse(a.distribution || "{}"),
          vmodel: number[] = []

        for (const key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key)) vmodel[key] = obj[key]
        }
        return vmodel
      })
    }
  }
}
